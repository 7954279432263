<template>
  <div>
    <div class="header">
      <van-nav-bar
        title="利息宝"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="background: #181e34;">
		<van-row>
			<table id="tabHold">
				<tbody>
					<tr>
						<td class="tFirst">可投资余额:</td>
						<td class="tSecond">{{orders.touzibalance||'0'}}</td>
					</tr>
					<tr>
						<td class="tFirst">投资期限:</td>
						<td class="tSecond">{{orders.days||''}}天</td>
					</tr>
					<tr>
						<td class="tFirst">投资收益:</td>
						<td class="tSecond">{{orders.rates||''}}%</td>
					</tr>
					<tr>
						<td class="tFirst">起投金额:</td>
						<td class="tSecond">{{orders.min||''}}元</td>
					</tr>
					<tr>
						<td class="tFirst">回款时间:</td>
						<td class="tSecond">{{orders.huikuantime||getFutureDate(orders.days||'')}}</td>
					</tr>
					<tr>
						<td class="tFirst">投资金额:</td>
						<td class="tSecond">
							<input id="money" placeholder="请输入金额" v-model="money">
						</td>
					</tr>
				</tbody>
			</table>
			<van-button type="primary" :loading="isloading" style="width: 90%;margin: 30px auto;background: #ff571e;border: none;" 
				@click="saveusdt">
				确认提交
			</van-button>
		</van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: {},
      recharges: [],
      withdraws: [],
      transfers: [],
      finished: true,
      loading: false,
	  ordertype:1,
	  money:'',
	  isloading:false,
    }
  },
  created() {
    this.getOrders();
  },
  mounted() {},
  methods: {
	  getFutureDate(n) {
		  if(!n){return ''};
	      const now = new Date();
	      now.setDate(now.getDate() + n); // 增加 n 天
	  
	      const year = now.getFullYear();
	      const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要 +1
	      const day = String(now.getDate()).padStart(2, '0');
	      const hours = String(now.getHours()).padStart(2, '0');
	      const minutes = String(now.getMinutes()).padStart(2, '0');
	      const seconds = String(now.getSeconds()).padStart(2, '0');
	  
	      return `${year}-${month}-${day} ${hours}:${minutes}`;
	  },
	 async  saveusdt(){
		  if(this.money==''){
			  return this.$toast.fail("请输入金额")
		  }
		  let formdata={
			  pid:this.$route.params.id,
			  money:this.money
		  }
		  this.isloading = true
		  const { data } = await this.$http.post(
		    '/home/user/doinvest',
		    formdata
		  )
		  if (data) {
		    if (data.code === 200) {
		      this.$toast.success('提交成功')
		      this.$router.push('/userinvest')
		    }else{
				this.$toast.fail(data.msg)
			}
		  }
		  this.isloading = false
	  },
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get('/home/user/getinvest/id/'+this.$route.params.id)
      if (data) {
        if (data.code === 200) {
          this.orders = data.data
        }
      }
    },
  }
}
</script>
<style lang="less" scoped>
	#tabHold{
		width: calc(100% - 16px);
		margin: 0 8px;
		border-collapse: collapse;
	}
	#tabHold tr {
	    text-align: center;
	    border-top: 0.026667rem solid #8686aa;
	    border-bottom: 0.026667rem solid #8686aa;
	}
	#tabHold tr td, #tabHold tr th {
	    height: 2.7rem;
	    border-right: 0.026667rem solid #8686aa;
		vertical-align: middle;
		font-size: 0.8rem;
		    color: #8686aa;
	}
	#money {
	    background: none;
	    border: none;
	    height: 100%;
		width: 100%;
	    outline: none;
	    color: #8686aa;
	}
	#tabHold tr td:last-child, #tabHold tr th:last-child {
	    border-right: none;
	}
	.tFirst{
		text-align: right;
		padding-right: 0.6rem;
		box-sizing: border-box;
	}
	.tSecond{
		text-align: left;
		padding-left: 0.6rem;
	}
.maincontent {
  padding: 46px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
}
</style>
